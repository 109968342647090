import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    name: "Login",
    meta: { requiresAuth: false, routeTitle: "Login" },
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue"),
  },
  {
    path: "/panel",
    name: "Panel",
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/user/Panel.vue"),
    meta: { requiresAuth: true, routeTitle: "Panel" },
    children: [
      {
        path: "/user/datasets",
        name: "Datasets",
        meta: { requiresAuth: true, routeTitle: "Datasets" },
        component: () =>
          import(/* webpackChunkName: "users" */ "../views/user/Datasets.vue"),
      },
      {
        path: "/user/home",
        name: "Home",
        meta: { requiresAuth: true, routeTitle: "Home" },
        component: () =>
          import(/* webpackChunkName: "users" */ "../views/user/Home.vue"),
      },

      {
        path: "/panel/ipredix/casting",
        name: "PredixCastingPlatform",
        meta: { requiresAuth: true, routeTitle: "RPEDIX Casting Platform" },
        component: () =>
          import(
            /* webpackChunkName: "manageuser" */ "../views/user/iPredix/PredixCasting.vue"
          ),
        // children: [
        //   {
        //     path: '/panel/ipredix/alloyproperties',
        //     name: 'AlloyProperties',
        //     meta: { requiresAuth: true, routeTitle: 'Alloy Properties' },
        //     component: () => import(/* webpackChunkName: "alloyproperties" */ '../views/user/casting/AlloyProperties.vue'),
        //   },
        //   // {
        //   //   path: '/panel/castingplatform/alloyproperties2',
        //   //   name: 'AlloyProperties2',
        //   //   meta: { requiresAuth: true, routeTitle: 'Alloy Properties2' },
        //   //   component: () => import(/* webpackChunkName: "alloyproperties2" */ '../views/user/casting/AlloyProperties2.vue'),
        //   // },
        //   {
        //     path: '/panel/castingplatform/benchmarking',
        //     name: 'Benchmarking',
        //     meta: { requiresAuth: true, routeTitle: 'Benchmarking Properties Against Standard Alloys' },
        //     component: () => import(/* webpackChunkName: "alloyproperties" */ '../views/user/casting/Benchmarking.vue'),
        //   }
        // ]
      },
      {
        path: "/panel/amplatform",
        name: "AmPlatform",
        meta: { requiresAuth: true, routeTitle: "AM Platform" },
        component: () =>
          import(
            /* webpackChunkName: "manageam" */ "../views/user/am/ManageAm.vue"
          ),
        children: [
          // {
          //   path: '/panel/castingplatform/alloyproperties',
          //   name: 'AlloyProperties',
          //   meta: { requiresAuth: true, routeTitle: 'Alloy Properties' },
          //   component: () => import(/* webpackChunkName: "alloyproperties" */ '../views/user/casting/AlloyProperties.vue'),
          // }
        ],
      },
      {
        path: "/panel/aimodel",
        name: "AiModel",
        meta: { requiresAuth: true, routeTitle: "AI Model Development" },
        component: () =>
          import(
            /* webpackChunkName: "manageai" */ "../views/user/ai/ManageAi.vue"
          ),
        children: [
          // {
          //   path: '/panel/castingplatform/alloyproperties',
          //   name: 'AlloyProperties',
          //   meta: { requiresAuth: true, routeTitle: 'Alloy Properties' },
          //   component: () => import(/* webpackChunkName: "alloyproperties" */ '../views/user/casting/AlloyProperties.vue'),
          // }
        ],
      },
      {
        path: "/panel/alloyDesign/additive",
        name: "AlloyDesignAdditive",
        meta: { requiresAuth: true, routeTitle: "Alloy Design Additive" },
        component: () =>
          import(
            /* webpackChunkName: "alloydesignadditive" */ "../views/user/alloyDesign/AlloyDesignAdditive.vue"
          ),
      },
      {
        path: "/panel/alloyDesign/casting",
        name: "AlloyDesignCasting",
        meta: { requiresAuth: true, routeTitle: "Alloy Design Casting" },
        component: () =>
          import(
            /* webpackChunkName: "alloydesigncasting" */ "../views/user/alloyDesign/AlloyDesignCasting.vue"
          ),
      },
      {
        path: "/panel/alloyDesign/wrought",
        name: "AlloyDesignWrought",
        meta: { requiresAuth: true, routeTitle: "Alloy Design Wrought" },
        component: () =>
          import(
            /* webpackChunkName: "alloydesignwrought" */ "../views/user/alloyDesign/AlloyDesignWrought.vue"
          ),
      },
      {
        path: "/panel/htdesign",
        name: "HtDesign",
        meta: { requiresAuth: true, routeTitle: "HT Design" },
        component: () =>
          import(
            /* webpackChunkName: "managehtdeisgn" */ "../views/user/htDesign/ManageHtDesign.vue"
          ),
        children: [
          {
            path: "/panel/htDesign/additive",
            name: "HtDesignAdditive",
            meta: { requiresAuth: true, routeTitle: "HT Design Additive" },
            component: () =>
              import(
                /* webpackChunkName: "htdesignadditive" */ "../views/user/htDesign/Additive.vue"
              ),
          },
          {
            path: "/panel/htDesign/casting",
            name: "HtDesignCasting",
            meta: { requiresAuth: true, routeTitle: "HT Design Casting" },
            component: () =>
              import(
                /* webpackChunkName: "htdesigncasting" */ "../views/user/htDesign/Casting.vue"
              ),
          },
          {
            path: "/panel/htDesign/wrought",
            name: "HtDesignWrought",
            meta: { requiresAuth: true, routeTitle: "HT Design Wrought" },
            component: () =>
              import(
                /* webpackChunkName: "htdesignwrought" */ "../views/user/htDesign/Wrought.vue"
              ),
          },
        ],
      },
      {
        path: "/panel/profile",
        name: "Profile",
        meta: { requiresAuth: true, routeTitle: "Profile" },
        component: () =>
          import(
            /* webpackChunkName: "profile" */ "../views/admin/profile/ManageProfile.vue"
          ),
        children: [
          {
            path: "/panel/profile/changepassword",
            name: "changePassword",
            meta: { requiresAuth: true, routeTitle: "Change Password" },
            component: () =>
              import(
                /* webpackChunkName: "changePassword" */ "../views/admin/profile/ChangePassword.vue"
              ),
          },
        ],
      },
    ],
  },
  {
    path: "/admin",
    name: "AdminHome",
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/admin/Home.vue"),
    meta: { requiresAuth: true, routeTitle: "Admin" },
    children: [
      {
        path: "/admin/users",
        name: "Users",
        meta: { requiresAuth: true, routeTitle: "Users" },
        component: () =>
          import(
            /* webpackChunkName: "users" */ "../views/admin/user/Users.vue"
          ),
      },
      {
        path: "/admin/users/:id",
        name: "ManagerUser",
        meta: { requiresAuth: true, routeTitle: "ManagerUser" },
        component: () =>
          import(
            /* webpackChunkName: "manageuser" */ "../views/admin/user/ManageUser.vue"
          ),
        children: [
          {
            path: "/admin/users/:id/info",
            name: "userInfo",
            meta: { requiresAuth: true, routeTitle: "User Profile" },
            component: () =>
              import(
                /* webpackChunkName: "userinfo" */ "../views/admin/user/UserInfo.vue"
              ),
          },
          {
            path: "/admin/users/:id/datasets",
            name: "userDatasets",
            meta: { requiresAuth: true, routeTitle: "User Databases" },
            component: () =>
              import(
                /* webpackChunkName: "userdatasets" */ "../views/admin/user/UserDatasets.vue"
              ),
          },
          {
            path: "/admin/users/:id/grantaccess",
            name: "grantDatasets",
            meta: {
              requiresAuth: true,
              routeTitle: "Grant Access to Databases",
            },
            component: () =>
              import(
                /* webpackChunkName: "userdatasets" */ "../views/admin/user/GrantDatasets.vue"
              ),
          },
        ],
      },
      {
        path: "/admin/databases",
        name: "Datasets",
        meta: { requiresAuth: true, routeTitle: "Databases" },
        component: () =>
          import(
            /* webpackChunkName: "databases" */ "../views/admin/dataset/Datasets.vue"
          ),
      },
      {
        path: "/admin/settings",
        name: "Settings",
        meta: { requiresAuth: true, routeTitle: "Settings" },
        component: () =>
          import(
            /* webpackChunkName: "settings" */ "../views/admin/Settings.vue"
          ),
        children: [
          {
            path: "/admin/settings/columngroups",
            name: "columnGroups",
            meta: { requiresAuth: true, routeTitle: "Column Groups" },
            component: () =>
              import(
                /* webpackChunkName: "columngroups" */ "../views/admin/settings/ColumnGroups.vue"
              ),
          },
          {
            path: "/admin/settings/general",
            name: "generalSettings",
            meta: { requiresAuth: true, routeTitle: "General Settings" },
            component: () =>
              import(
                /* webpackChunkName: "generalsettings" */ "../views/admin/settings/General.vue"
              ),
          },
        ],
      },
      {
        path: "/admin/profile",
        name: "Profile",
        meta: { requiresAuth: true, routeTitle: "Profile" },
        component: () =>
          import(
            /* webpackChunkName: "profile" */ "../views/admin/profile/ManageProfile.vue"
          ),
        children: [
          {
            path: "/admin/profile/changepassword",
            name: "changePassword",
            meta: { requiresAuth: true, routeTitle: "Change Password" },
            component: () =>
              import(
                /* webpackChunkName: "changePassword" */ "../views/admin/profile/ChangePassword.vue"
              ),
          },
        ],
      },
      {
        path: "/admin/databases/:id",
        name: "ManageDataset",
        meta: { requiresAuth: true, routeTitle: "Manage Databases" },
        component: () =>
          import(
            /* webpackChunkName: "managedatabases" */ "../views/admin/dataset/ManageDataset.vue"
          ),
        children: [
          {
            path: "/admin/databases/:id/info",
            name: "datasetInfo",
            meta: { requiresAuth: true, routeTitle: "Database Information" },
            component: () =>
              import(
                /* webpackChunkName: "datasetinfo" */ "../views/admin/dataset/DatasetInfo.vue"
              ),
          },
          {
            path: "/admin/databases/:id/upload",
            name: "datasetUpload",
            meta: { requiresAuth: true, routeTitle: "Upload Database" },
            component: () =>
              import(
                /* webpackChunkName: "datasetinfo" */ "../views/admin/dataset/DatasetUpload.vue"
              ),
          },
          {
            path: "/admin/databases/:id/columns",
            name: "datasetColumns",
            meta: { requiresAuth: true, routeTitle: "Upload Database" },
            component: () =>
              import(
                /* webpackChunkName: "datasetinfo" */ "../views/admin/dataset/DatasetColumns.vue"
              ),
          },
          {
            path: "/admin/databases/:id/analyze",
            name: "analyzeDataset",
            meta: { requiresAuth: true, routeTitle: "Analyze Database" },
            component: () =>
              import(
                /* webpackChunkName: "datasetinfo" */ "../views/admin/dataset/AnalyzeDataset.vue"
              ),
          },
        ],
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const auth = store.state.Login;
    if (!auth) {
      store.commit("logout");
      next({ path: "/login", query: { redirect: to.fullPath } });
    } else {
      // check if user has access to this route
      const path = to.path;
      if (path.startsWith("/admin") && store.state.isStaff === false) {
        store.commit("logout");
        next({ path: "/login", query: { redirect: to.fullPath } });
      } else {
        document.title = "iMetal: " + to.meta.routeTitle;
        next();
      }
      // const token = store.state.Token
      // const method = 'get'
      // const project = 'pid' in to.params ? to.params.pid : null
      // postaccess(project, token, method, path, function (res) {
      //   if (res.hasAccess === true) {
      //     // --
      //     // this is temprory , check if user is demo then force to search page only
      //     // --
      //     if (store.state.UserEmail.includes('demo')) {
      //       if (!to.path.includes('catalogs')) { next({ path: '/catalogs' }) }
      //       if (to.params.id && !to.path.includes('/search')) { next({ path: '/catalogs/' + to.params.id + '/search' }) }
      //     }
      //     document.title = 'DolphinAI : ' + to.meta.routeTitle
      //     next()
      //   } else {
      //     next({ path: '/restricted' })
      //   }
      // }, function () {
      //   // error checking access , force logout
      //   store.commit('logout')
      //   next({ path: '/login', query: { redirect: to.fullPath } })
      // })
    }
  } else {
    document.title = "iMetal : " + to.meta.routeTitle;
    next();
  }
});

export default router;
